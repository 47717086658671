import { Controller } from "@hotwired/stimulus";
import Swiper, { Navigation, SwiperOptions } from "swiper";
import "swiper/css";
import "swiper/css/navigation";

Swiper.use([Navigation]);

export default class extends Controller {
  declare swiper: Swiper | undefined;
  declare optionsValue: SwiperOptions;

  static values = {
    options: Object,
  };

  connect(): void {
    this.swiper = new Swiper(this.element as HTMLElement, {
      modules: [Navigation],
      ...this.defaultOptions,
      ...this.optionsValue,
    });
  }

  disconnect(): void {
    if (this.swiper) {
      this.swiper.destroy();
    }
    this.swiper = undefined;
  }

  get defaultOptions(): SwiperOptions {
    return {
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    };
  }
}
