import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "state", "unsent", "count", "alert"];
  static values = {
    required: Array,
  };

  connect() {
    this.visibleReview();
    this.visibleOtherCategory();
    this.visibleReason();
    this.localStorageKey = window.location;
    this.renderCount();
    this.changeSubmitButton();
  }

  changeSubmitButton() {
    const submitButton = document.querySelector('button[type="submit"]');
    if (this.formTarget.checkValidity()) {
      submitButton.classList.remove("disabled");
    } else {
      submitButton.classList.add("disabled");
    }
  }

  onChange(event) {
    if (event.currentTarget.name == "form[入社可能時期]") {
      this.visibleReason();
    }
    if (event.currentTarget.id == "form_直近の職種カテゴリ_その他__その他") {
      this.visibleOtherCategory();
    }
    if (event.currentTarget.name == "form[withworkをどこで知りましたか？]") {
      this.visibleReview();
    }
  }

  visibleReason() {
    const otherTextChecked = document.querySelector(
      "#form_入社可能時期_未定:checked",
    );
    if (otherTextChecked) {
      document.querySelector(
        "#form-uncertain_start_date_reason__c",
      ).style.display = "block";
      document.querySelector("#form_未定の理由").setAttribute("required", "");
    } else {
      document.querySelector(
        "#form-uncertain_start_date_reason__c",
      ).style.display = "none";
      document.querySelector("#form_未定の理由").removeAttribute("required");
    }
  }

  visibleOtherCategory() {
    const otherTextChecked = document.querySelector(
      "#form_直近の職種カテゴリ_その他__その他:checked",
    );
    if (otherTextChecked) {
      document.querySelector("#form-TalentRecentJobFreeText__c").style.display =
        "block";
    } else {
      document.querySelector("#form-TalentRecentJobFreeText__c").style.display =
        "none";
    }
  }

  visibleReview() {
    const checked = document.querySelector(
      "#form-ninchikeiro__c input:checked",
    );
    if (checked && checked.value == "知人の口コミ・紹介") {
      document.querySelector("#form-introducer__c").style.display = "block";
    } else {
      document.querySelector("#form-introducer__c").style.display = "none";
    }
  }

  countRequiredAndFilled() {
    let totalRequired = 0;
    let filledRequired = 0;
    let requiredFieldNames = [];

    // デバッグ用のログを追加
    // console.group('Form Validation Check');

    this.formTarget
      .querySelectorAll("[data-form-section]")
      .forEach((section) => {
        // 非表示の要素は必須チェックから除外
        if (getComputedStyle(section).display === "none") return;

        const isRequired = section.dataset.required === "true";
        if (!isRequired) return;

        // セクションのIDまたはクラスをログ
        // console.group(`Checking section: ${section.dataset.name}`);

        let isSectionFilled = false;
        totalRequired++;

        switch (section.dataset.validationType) {
          case "checkbox-group":
            const checkedBoxes = Array.from(
              section.querySelectorAll('input[type="checkbox"]'),
            ).filter((cb) => cb.checked);
            isSectionFilled = checkedBoxes.length > 0;
            //console.log('Checkbox group:', isSectionFilled, checkedBoxes.length);
            break;

          case "radio-group":
            // validation_selector が指定されている場合はそれを使う
            const radioSelector =
              section.dataset.validationSelector || 'input[type="radio"]';
            const radios = section.querySelectorAll(radioSelector);
            isSectionFilled = Array.from(radios).some((radio) => radio.checked);
            //console.log('Radio group:', isSectionFilled, Array.from(radios).map(r => r.checked));
            break;

          default:
            const inputs = section.querySelectorAll("input, textarea");
            isSectionFilled = Array.from(inputs).some((input) => {
              const isVisible = input.offsetParent !== null;
              return input.value.length > 0 && isVisible;
            });
            // console.log('Default inputs:', isSectionFilled);
            break;
        }

        // 入力状態をdata属性に反映
        section.dataset.filled = isSectionFilled;

        if (isSectionFilled) {
          filledRequired++;
        } else {
          requiredFieldNames.push([section.dataset.name, section.id]);
        }

        //console.log('Section filled:', isSectionFilled);
        // console.groupEnd();
      });

    //console.log(`Total required: ${totalRequired}, Filled: ${filledRequired}`);
    //console.groupEnd();
    // console.log(requiredFieldNames)

    return {
      requiredFieldNames: requiredFieldNames,
      required: totalRequired,
      filled: filledRequired,
    };
  }

  isElementVisible(element) {
    if (!element) return false;

    // offsetParent === null のチェックを必ず最後に行う
    const style = window.getComputedStyle(element);
    if (style.display === "none" || style.visibility === "hidden") {
      return false;
    }

    // 親要素をチェックする場合は、display: none と visibility: hidden だけを見る
    let parent = element.parentElement;
    while (parent) {
      const parentStyle = window.getComputedStyle(parent);
      if (
        parentStyle.display === "none" ||
        parentStyle.visibility === "hidden"
      ) {
        return false;
      }
      parent = parent.parentElement;
    }

    return true;
  }

  renderCount() {
    const result = this.countRequiredAndFilled();
    if (result.filled < result.required) {
      this.countTarget.innerHTML = `必須：${result.required} / 入力済み：${result.filled}`;
    } else {
      this.countTarget.innerHTML = "";
    }
    if (result.requiredFieldNames.length == 0) {
      this.alertTarget.style.display = "none";
    } else {
      const messageHeader = "下記が未入力です";
      const fieldList = result.requiredFieldNames
        .map(([name, id]) => {
          return `* <a href="#${id}">${name}</a>`;
        })
        .join("\n");

      this.alertTarget.style.display = "block";
      this.alertTarget.innerHTML = `${messageHeader}\n${fieldList}`;
    }
  }

  perist(e) {
    this.renderCount();

    // show auth-save
    const section = e.target.closest(".input");
    this.stateTarget.dataset.state = "saving";
    this.stateTarget.style.top = `${section.offsetTop}px`;

    const payload = new FormData(this.formTarget);
    // perist data
    const token = document.getElementsByName("csrf-token")[0].content;
    const headers = {
      "X-CSRF-Token": token,
      Accept: "application/json",
    };
    fetch(location.href, {
      method: "PATCH",
      headers: headers,
      body: payload,
    }).then((_) => {
      this.stateTarget.dataset.state = "saved";
      this.unsentTarget.dataset.state = "true";
    });
  }
}
